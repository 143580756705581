.DayPickerInput{
    width: 100%;
}
.DayPickerInput input{
    border: none;
    border-bottom: 1px solid #d6d6d6;
    padding: 5px;
    font-size: 17px;
    width: 100%;
    margin-top: 25px;
}