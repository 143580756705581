.request table {
    display: block;
    overflow-x: scroll;
    height: 500px;
    width: 100%;
}

.request table thead {
    flex: 0 0 auto;
    width: calc(100% - 0.9em);
}

.request table tbody {
    flex: 1 1 auto;
    display: block;
}

.request table tbody tr {
    width: 100%;
}

.request table thead, .request table tbody tr {
    display: table;
    table-layout: fixed;
}

.rdtPicker td {
    color: #000;
}

.rdtPicker td.rdtToday {
    color: #cc0e00;
}

.request td {
    border-right: 1px solid #4d4d4d;
    width: 100px;
    text-align: center;
    cursor: pointer;

}

.request tbody tr {
    background: #fff;

}

.request tbody tr:nth-child(2n+1) {
    background: #e9e9e9;

}

.request tbody tr div {
    padding: 5px;
}

.request td:first-child {
    padding: 10px;
    width: 300px;

}

.booked {
    cursor: pointer;
    background: #3cc;
    color: #fff;
    width: 100%;
    height: 100%;
}

.booked-hotel {
    cursor: pointer;
    background: #cc543c;
    color: #fff;
    width: 100%;
    height: 100%;
}